<template>
  <div class="app-container">
    <el-dialog title="" :visible.sync="dialogNPAView" width="70%">
      <div v-if="document">
        <NPA :document="document" />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="2500"
            :filename="`NPA - ${document.employee_details.name}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="legal"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
          <section slot="pdf-content">
            <NPA :document="document" />
          </section>
        </vue-html2pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogView = false">Cancel</el-button>
        <el-button type="primary" @click="generateReport">Download</el-button>
        <el-button type="danger" @click="revision">Tag for Revision</el-button>
        <el-button type="success" @click="verify">Verify</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Document" :visible.sync="dialogEdit" width="45%">
      <div v-if="dialogEdit">
        <el-form :model="formEdit" ref="form" label-width="170px" label-position="left">
          <div class="npaDetails">
            <el-row>
              <el-form-item label="Document Movement">
                <el-select v-model="formEdit.npa_bg4_id" filterable disabled placeholder="Select Movement" size="mini">
                  <el-option v-for="move in movement" :key="move.id" :label="move.name" :value="move.id"></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <div class="records">
              <el-row>
                <el-col :span="8"><h3>CURRENT RECORD</h3></el-col>
                <el-col :span="8"><h3>DESCRIPTION</h3></el-col>
                <el-col :span="8"><h3>NEW RECORD</h3></el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="8">{{details.bp_id_name}}</el-col>
              <el-col :span="8">{{description[0].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.bp_id" disabled filterable placeholder="Select Job Title" justify="left" size="mini">
                  <el-option v-for="job in jobTitles" :key="job.id" :label="job.name" :value="job.id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.bj_id_name}}</el-col>
              <el-col :span="8">{{description[1].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.bj_id" filterable disabled placeholder="Select Job Level" size="mini">
                  <el-option v-for="level in jobLevels" :key="level.id" :label="level.name" :value="level.id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.bes_id_name}}</el-col>
              <el-col :span="8">{{description[2].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.bes_id" filterable disabled placeholder="Select Employment Status" size="mini">
                  <el-option v-for="status in employmentStatus" :key="status.id" :label="status.name" :value="status.id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="8">{{details.basicsalary}}</el-col> -->
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.old_basicsalary" size="mini"></el-input>
              </el-col>
              <el-col :span="8">{{description[3].name}}</el-col>
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.basicsalary" size="mini"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="8">{{details.deminimis}}</el-col> -->
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.old_deminimis" size="mini"></el-input>
              </el-col>
              <el-col :span="8">{{description[4].name}}</el-col>
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.deminimis" size="mini"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="8">{{details.cola}}</el-col> -->
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.old_cola" size="mini"></el-input>
              </el-col>
              <el-col :span="8">{{description[5].name}}</el-col>
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.cola" size="mini"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="8">{{details.allowance}}</el-col> -->
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.old_allowance" size="mini"></el-input>
              </el-col>
              <el-col :span="8">{{description[6].name}}</el-col>
              <el-col :span="8">
                <el-input type="number" v-model="formEdit.allowance" size="mini"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.benefits}}</el-col>
              <el-col :span="8">{{description[7].name}}</el-col>
              <el-col :span="8">
                <el-input v-model="formEdit.benefits" size="mini"></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.immediate_supervisor}}</el-col>
              <el-col :span="8">{{description[8].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.boss_em_id" filterable disabled placeholder="Select Supervisor" size="mini">
                  <el-option v-for="supervisor in supervisors" :key="supervisor.id" :label="supervisor.em_id_name" :value="supervisor.em_id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.bd_id_name}}</el-col>
              <el-col :span="8">{{description[9].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.bd_id" @change="getSectionDepartment" disabled filterable placeholder="Select Department" size="mini">
                  <el-option v-for="departments in departments" :key="departments.id" :label="departments.name" :value="departments.id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.bds_id_name}}</el-col>
              <el-col :span="8">{{description[10].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.bds_id" filterable disabled placeholder="Select Section" size="mini">
                  <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">{{details.bbl_id_homebased_name}}</el-col>
              <el-col :span="8">{{description[11].name}}</el-col>
              <el-col :span="8">
                <el-select v-model="formEdit.bbl_id_homebased" filterable disabled placeholder="Select Location" size="mini">
                  <el-option v-for="location in locations" :key="location.id" :label="location.name" :value="location.id"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row>
            <el-form-item label="Effectivity Date">
              <el-date-picker v-model="formEdit.effectivity_date_to" disabled type="date" placeholder="Effectivity Date" value-format="yyyy-MM-dd" size="mini"></el-date-picker>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Justification">
              <el-input v-model="formEdit.superior_justification" disabled type="textarea" size="mini"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Performance Rating">
              <el-input type="number" v-model="formEdit.performance_rating" disabled size="mini"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Performance Google Link">
              <el-input type="text" v-model="formEdit.performance_googlelink" disabled size="mini"></el-input>
            </el-form-item>
          </el-row>
          </div>
          <el-row type="flex" justify="end">
            <el-button @click="dialogEdit = false">Cancel</el-button>
            <el-button type="primary" @click="editNPA">Confirm</el-button>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">DOCUMENT REQUEST DASHBOARD</h3>
        <div>
          <el-select v-model="isDept" filterable @change="getNPAList" placeholder="Select Department">
            <el-option label="All" :value="0"></el-option>
            <el-option v-for="departments in departments" :key="departments.id" :label="departments.name" :value="departments.id"></el-option>
          </el-select>
          <download-excel class= "el-button" :fetch="download" :fields="json_fields">Download Selected Documents</download-excel>
        </div>
      </div>
      <el-table :data="data" border class="hidden-sm-and-down" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="EMPLOYEE" prop="em_id_name"></el-table-column>
        <el-table-column label="DESCRIPTION" prop="npa_bg4_id_name"></el-table-column>
        <el-table-column label="DATE HIRED" prop="datehired">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.datehired) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="mss_a_id_name"></el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="View" placement="top-start">
              <el-button v-if="scope.row.mss_a_id === '9'" size="mini" type="primary" @click="view(scope.row)"><i class="el-icon-document"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Edit" placement="top-start">
              <el-button v-if="scope.row.mss_a_id === '9'" size="mini" type="primary" @click="openEditor(scope.row)"><i class="el-icon-edit"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'
import NPA from '@/components/Documents/NPA.vue'

export default {
  components: {
    NPA
  },
  data () {
    return {
      json_fields: {},
      npa: null,
      document: null,
      dialogEdit: false,
      id: null,
      ids: [],
      movement: [],
      jobTitles: [],
      jobLevels: [],
      employmentStatus: [],
      supervisors: [],
      departments: [],
      sections: [],
      locations: [],
      dialogView: false,
      dialogNPAView: false,
      formEdit: {
        em_id: null,
        hed_id: null,
        bar_id: null,
        npa_bg4_id: null,
        bp_id: null,
        bj_id: null,
        bes_id: null,
        basicsalary: null,
        old_basicsalary: null,
        old_deminimis: null,
        old_cola: null,
        old_allowance: null,
        deminimis: null,
        cola: null,
        allowance: null,
        benefits: null,
        boss_em_id: null,
        bd_id: null,
        bds_id: null,
        bbl_id_homebased: null,
        effectivity_date_from: null,
        effectivity_date_to: null,
        document_release_date: null,
        superior_justification: null,
        performance_rating: null,
        performance_googlelink: null
      },
      data: [],
      isDept: null
    }
  },
  mounted () {
    this.getNPAList()
    this.getMovement()
    this.getJobTitles()
    this.getJobLevels()
    this.getEmploymentStatus()
    this.getSupervisor()
    this.getDepartment()
    this.getSection()
    this.getLocation()
  },
  methods: {
    getNPAList () {
      const isDeptid = this.isDept !== null ? this.isDept : 0
      this.$http
        .get(`hr.document-filter-list/${isDeptid}`)
        .then(res => {
          this.data = res.data.body
        })
    },
    view (row) {
      this.id = row.id
      this.bar_id = row.bar_id
      this.em_id = row.em_id
      this.$http
        .get(`hr.document-form-data/${row.id}/${row.bar_id}/${row.em_id}`)
        .then(res => {
          this.document = res.data.body
          if (this.document.bar_id === 1) {
            this.dialogNPAView = true
          } else if (this.document.bar_id === 2) {
            this.dialogMPPView = true
          }
        })
    },
    revision () {
      this.$prompt('Are you sure you want to tag this NPA for revision?', 'Tip', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputErrorMessage: 'Invalid Email'
      }).then(({ value }) => {
        let form = {
          id: this.id,
          mss_a_id: 20
        }
        this.$http
          .post('mss.document-update', form)
          .then(res => {
            this.$message({
              type: 'success',
              message: 'NPA tagged successfully'
            })
            this.getNPAList()
            this.dialogNPAView = false
            this.dialogMPPView = false
          })
        form = {
          hed_id: this.id,
          comment: value
        }
        this.$http
          .post('hr.create-document-comment', form)
          .then(res => {
            this.$message({
              type: 'success',
              message: 'Commentted successfully'
            })
          })
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: 'Submit canceled'
        })
      })
    },
    verify () {
      this.$confirm('Are you sure you want to verify this NPA', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        const form = {
          id: this.id,
          mss_a_id: 4
        }
        this.$http
          .post('mss.document-update', form)
          .then(res => {
            this.$message({
              type: 'success',
              message: 'NPA approved successfully'
            })
            this.getNPAList()
            this.dialogNPAView = false
            this.dialogMPPView = false
          })
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: 'Submit canceled'
        })
      })
    },
    async download () {
      const form = {
        ids: this.ids.map(record => record.id)
      }
      if (form.ids.length > 0) {
        const response = await this.$http.post('hr.print-selected-data', form)
        console.log(response)
        this.json_fields = response.data.body.json_field
        return response.data.body.json_data
      }
    },
    handleSelectionChange (val) {
      this.ids = val
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Submitted') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    getMovement () {
      this.$http
        .get('backend.global4-list/87')
        .then(res => {
          this.movement = res.data.body
        })
    },
    getJobTitles () {
      this.$http
        .get('backend.global4-list/30')
        .then(res => {
          this.jobTitles = res.data.body
        })
    },
    getJobLevels () {
      this.$http
        .get('backend.global4-list/31')
        .then(res => {
          this.jobLevels = res.data.body
        })
    },
    getEmploymentStatus () {
      this.$http
        .get('backend.employment-status-list')
        .then(res => {
          this.employmentStatus = res.data.body
        })
    },
    getSupervisor () {
      this.$http
        .get(`mss.personnels-list/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.supervisors = res.data.body
        })
    },
    getDepartment () {
      this.$http
        .get('backend.department-list-by-company/1')
        .then(res => {
          this.departments = res.data.body
        })
    },
    getSection () {
      this.$http
        .get('backend.department.section-list')
        .then(res => {
          this.sections = res.data.body
        })
    },
    getLocation () {
      this.$http
        .get('locationinfo.locations/1')
        .then(res => {
          this.locations = res.data.body
        })
    },
    getSectionDepartment () {
      this.sections = []
      this.bd_id = this.formEdit.bd_id
      this.$http
        .get(`backend.department.section-list/${this.bd_id}`)
        .then(res => {
          if (!res.data.body) {
            this.getSection()
          } else {
            this.sections = res.data.body
          }
        })
    },
    openEditor (data) {
      this.formEdit.hed_id = data.id
      this.formEdit.em_id = data.em_id
      this.formEdit.bar_id = data.bar_id
      this.movement = []
      this.jobTitles = []
      this.jobLevels = []
      this.employmentStatus = []
      this.supervisors = []
      this.departments = []
      this.sections = []
      this.locations = []
      const form = {
        em_id: data.em_id,
        bar_id: data.bar_id,
        id: data.id
      }
      this.$http
        .post('mss.select-document', form)
        .then(res => {
          this.formEdit.npa_bg4_id = null
          this.formEdit.bp_id = null
          this.formEdit.bj_id = null
          this.formEdit.bes_id = null
          this.formEdit.basicsalary = null
          this.formEdit.old_basicsalary = null
          this.formEdit.old_deminimis = null
          this.formEdit.old_cola = null
          this.formEdit.old_allowance = null
          this.formEdit.deminimis = null
          this.formEdit.cola = null
          this.formEdit.allowance = null
          this.formEdit.benefits = null
          this.formEdit.boss_em_id = null
          this.formEdit.bd_id = null
          this.formEdit.bds_id = null
          this.formEdit.bbl_id_homebased = null
          this.formEdit.effectivity_date_from = null
          this.formEdit.effectivity_date_to = null
          this.formEdit.document_release_date = null
          this.formEdit.superior_justification = null
          this.formEdit.performance_rating = null
          this.formEdit.performance_googlelink = null
          this.formEdit.npa_bg4_id = res.data.body.employee_details.npa_bg4_id
          this.formEdit.bp_id = res.data.body.employee_details.new_bp_id
          this.formEdit.bj_id = res.data.body.employee_details.new_bj_id
          this.formEdit.bes_id = res.data.body.employee_details.new_bes_id
          this.formEdit.basicsalary = res.data.body.employee_details.new_basicsalary.replace(/,/g, '')
          this.formEdit.old_basicsalary = res.data.body.employee_details.old_basicsalary.replace(/,/g, '')
          this.formEdit.old_deminimis = res.data.body.employee_details.old_deminimis.replace(/,/g, '')
          this.formEdit.old_cola = res.data.body.employee_details.old_cola.replace(/,/g, '')
          this.formEdit.old_allowance = res.data.body.employee_details.old_allowance.replace(/,/g, '')
          this.formEdit.deminimis = res.data.body.employee_details.new_deminimis.replace(/,/g, '')
          this.formEdit.cola = res.data.body.employee_details.new_cola.replace(/,/g, '')
          this.formEdit.allowance = res.data.body.employee_details.new_allowance.replace(/,/g, '')
          this.formEdit.benefits = res.data.body.employee_details.new_benefits
          this.formEdit.boss_em_id = res.data.body.employee_details.new_boss_em_id
          this.formEdit.bd_id = res.data.body.employee_details.new_bd_id
          this.formEdit.bds_id = res.data.body.employee_details.new_bds_id
          this.formEdit.bbl_id_homebased = res.data.body.employee_details.new_bbl_id
          this.formEdit.superior_justification = res.data.body.employee_details.superior_justification
          this.formEdit.performance_rating = res.data.body.employee_details.performance_rating
          this.formEdit.performance_googlelink = res.data.body.employee_details.performance_googlelink
          this.formEdit.effectivity_date_to = res.data.body.employee_details.effectivity_date_to
          this.movement.push({
            id: res.data.body.employee_details.npa_bg4_id,
            name: res.data.body.employee_details.npa_bg4_id_name
          })
          this.jobTitles.push({
            id: res.data.body.employee_details.new_bp_id,
            name: res.data.body.employee_details.new_bp_id_name
          })
          this.jobLevels.push({
            id: res.data.body.employee_details.new_bj_id,
            name: res.data.body.employee_details.new_bj_id_name
          })
          this.employmentStatus.push({
            id: res.data.body.employee_details.new_bes_id,
            name: res.data.body.employee_details.new_bes_id_name
          })
          this.supervisors.push({
            id: res.data.body.employee_details.new_boss_em_id,
            name: res.data.body.employee_details.new_boss_em_id_name
          })
          this.departments.push({
            id: res.data.body.employee_details.new_bd_id,
            name: res.data.body.employee_details.new_bd_id_name
          })
          this.sections.push({
            id: res.data.body.employee_details.new_bds_id,
            name: res.data.body.employee_details.new_bds_id_name
          })
          this.locations.push({
            id: res.data.body.employee_details.new_bbl_id,
            name: res.data.body.employee_details.new_bbl_id_name
          })
          this.getJobTitles()
          this.getJobLevels()
          this.getMovement()
          this.getEmploymentStatus()
          this.getSupervisor()
          this.getDepartment()
          this.getSection()
          this.getLocation()
          this.effectivity_date = null
          this.details = res.data.body.employee_details
          this.description = res.data.body.description
          this.dialogEdit = true
          // this.locations = res.data.body
        })
    },
    editNPA () {
      this.$confirm('Are you sure you want to submit this NPA', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.formEdit.effectivity_date_from = this.formEdit.effectivity_date_to
        this.dialogEdit = false
        this.$http
          .post('mss.create-npa-document', this.formEdit)
          .then(() => {
            this.$message({
              type: 'success',
              message: 'NPA Document successfully updated'
            })
            this.getNPAList()
          })
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: 'Submit canceled'
        })
      })
    }
  }
}
</script>
